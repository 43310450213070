<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="ball-triangle-path"
  [fullScreen]="true"></ngx-spinner>





<div class="maincontent_wrapper">
  <app-header />
  <router-outlet></router-outlet>



  <footer *ngIf="footer && isBrowser">
    <div class="container">
      <div class="deskFlex_mobileBlock align_item_Center flexWrap justify-content_Spacebetween mb_34">
        <div>
          <a href="#"> <img class="footerLogo" src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/demo-sayshop/slices/theecode.svg" /></a>
        </div>
        <div>
          <ul class="Display_Flex align_item_Center emptyList gap_20 socialMenu">
            <li>
              <a href="mailto:hai@theecode.xyz">
                <img class="width28" src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/demo-sayshop/slices/sms_icon.svg" />
              </a>
            </li>
            <li>
              <a target="_blank" href="https://www.linkedin.com/company/theecode/">
                <img class="width28" src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/demo-sayshop/slices/linkedin_icon.svg" />
              </a>
            </li>
          </ul>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-3 mobSpace_mb24">
          <h2 class="fontSize20 fontWeight500 mb_24 neroText">US Headquarters</h2>

          <div class="Display_Flex gap_12 mb_12">
            <div>
              <img src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/demo-sayshop/slices/location.svg" />
            </div>
            <div>
              <p class="fontSize14 fontWeight400 neroText">16192 Coastal HwyLewes, DE 19958, USA.</p>
            </div>
          </div>


          <a href="tel:+1 646-386-8799" class="Display_Flex align_item_Center gap_12">
            <div>
              <img class="width20" src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/demo-sayshop/slices/call_icon.svg" />
            </div>
            <div>
              <p class="fontSize14 fontWeight400 neroText">(+1) 646-386-8799</p>
            </div>
          </a>
        </div>
        <div class="col-lg-3 mobSpace_mb24">
          <h2 class="fontSize20 fontWeight500 mb_24 neroText">UAE Office</h2>
          <div class="Display_Flex gap_12">
            <div>
              <img src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/demo-sayshop/slices/location.svg" />
            </div>
            <div>
              <p class="fontSize14 fontWeight400 neroText mb_12">Al Nahayan, P.O Box 4454, Abu Dhabi,</p>

              <p class="fontSize14 fontWeight400 neroText">United Arab Emirates.</p>
            </div>
          </div>
        </div>
        <div class="col-lg-4 mobSpace_mb24">
          <h2 class="fontSize20 fontWeight500 mb_24 neroText">Chennai Office</h2>
          <div class="Display_Flex gap_12">
            <div>
              <img src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/demo-sayshop/slices/location.svg" />
            </div>
            <div>
              <p class="fontSize14 fontWeight400 mb_12 neroText">
                Theecode Technologies Private Limited ,
              </p>
              <p class="fontSize14 fontWeight400 mb_12 neroText">High Street V3 New, No. 431 ,</p>
              <p class="fontSize14 fontWeight400 mb_12 neroText">Anna Salai,</p>
              <p class="fontSize14 fontWeight400 neroText">Chennai - 600018.</p>
            </div>
          </div>
        </div>
        <div class="col-lg-2 Display_Flex align_item_Center justify_content_center">
          <div class="soc_img">
            <img src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/demo-sayshop/slices/soc.png" />
          </div>
        </div>
      </div>
    </div>
  </footer>

  <div class="copyRightSec">
    <div class="container">
      <div class="row">
        <div class="col-md-6 mobSpace_mb24">
          <a href="index.html">
            <p class="steelWoolText fontSize14 fontWeight300">© Copyright 2023 Theecode Pvt Ltd . All Rights
              Reserved</p>
          </a>
        </div>
        <div class="col-md-6">
          <ul class="policy_Condition">
            <li>
              <a href="termsandconditions.html" class="steelWoolText fontSize14 fontWeight300">Terms and
                Condition </a>
            </li>
            <li>
              <a href="privacy_policy.html" class="steelWoolText fontSize14 fontWeight300">Privacy policy
              </a>
            </li>
            <li>
              <a href="refund_policy.html" class="steelWoolText fontSize14 fontWeight300">Refund
                Policy</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>


  <!-- CURSOR -->
  <div class="mouse-cursor cursor-outer"></div>
  <div class="mouse-cursor cursor-inner"></div>
  <!-- /CURSOR -->
  <div class="progress-wrap">
    <svg class="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
      <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
    </svg>
  </div>

  <div class="progress-wrap">
    <svg class="progress-circle svg-content" width="100%" height="100%" viewBox="-1 -1 102 102">
      <path d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98" />
    </svg>
  </div>
</div>
