<div *ngIf="footer">


  <section class="common_bnr">
    <div class="container ">
      <div class="row align_item_Center mob_flexDir_colReve">
        <div class="col-lg-5 ">

          <div class="bannerContent">
            <span class="displayBlock aconitePurpleText fontWeight500 fontSize16 mb_16">A snippet of our
              Knowledge</span>
            <h2 class="mb_34">Insights </h2>

            <p class="fontWeight300 fontSize16 mb_24 steelWoolText">Welcome to Theecode's realm of insights,
              where we merge technology and knowledge. Explore our Insightful blogs, your destination for
              engaging discussions on a variety of topics, all fuelled by our passion for innovation.</p>
            <a href="contactus.html"
              class="Mainbutton secondary_clr displayInlineFlex align_item_Center justify_content_center">Lets&nbsp;Connect</a>

          </div>
        </div>
        <div class="col-lg-7 mobSpace_mb34">

          <img class="width100per"
            src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/demo-sayshop/slices/blogListpage.jpg" />
        </div>
      </div>
    </div>
  </section>
  <section class="insights_sec" id="blogs">

    <div class="container">
      <div class="newsroomItems" >
        <div class="row">
          <div class="col-md-4 mb_50 " *ngFor="let blog of blogList">
            <div *ngIf="blog?.page?.short_image" class="blog_img">
              <img [src]="blog?.page?.short_image" alt="blog_img" />
            </div>
            <div class="Display_Flex align_item_Center justify-content_Spacebetween">
              <div class="Display_Flex align_item_Center gap_12">
                <img class="width18 cursorPointer"
                  src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/demo-sayshop/slices/calendar_icon.svg">
                <span class="fontSize14 fontWeight400 aconitePurpleText">{{formatDate(blog.page.created_at)}}</span>
              </div>
              <a href="https://www.linkedin.com/sharing/share-offsite/?url=https://www.theecode.xyz/enhancing-lending-efficiency.html"
                class="Display_Flex align_item_Center gap_12 ">
                <img class="width18"
                  src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/demo-sayshop/slices/share_icon.svg">
                <span class="fontSize14 fontWeight400 byzantineText">Share</span>
              </a>
            </div>
            <div class="cerebralGrey_borderBottom mb_12 pb_12"></div>
            <div class="blogoverflow">
              <a (click)="navigate(blog.slug)" class="blogTitle displayBlock fontSize16 fontWeight400 neroText mb_16 cursorPointer">
                {{ blog?.blog_name }}
              </a>
              <p class="fontSize14 fontWeight300 steelWoolText mb_24 ">{{ blog?.page?.short_content ?? "No
                Description" }}</p>
            </div>
          </div>
        </div>
        <!-- <div class="extraordinary_abundance_bottomborder padding_Bottom16 margin_Top24 margin_Bottom24"></div> -->
      </div>
      <div>
        <app-pagination [totalItems]="totalItems" [itemsPerPage]="itemsPerPage" [currentPage]="currentPage"
          (pageChanged)="onPageChange($event)"></app-pagination>

      </div>
    </div>


  </section>

  <!-- Display your data based on pagination -->
  <section *ngIf="newsList?.length" class="newsroom_blog">
    <div class="container">

      <div class="Display_Flex justify-content_Spacebetween align_item_Center margin_Bottom34">
        <div>
          <h2 class="font_Size32 infiniteNightText font_Weight600">Newsroom</h2>
          <div>
            <img src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets/small_phaserBeamLinebottom.svg"
              alt="small_phaserBeamLinebottom">
          </div>

        </div>
        <div>
          <a (click)="newslistNavigate()"><button class="Mainbutton infiniteNightBtn_clr">Show all</button></a>

        </div>
      </div>



      <div class="owl-carousel owl-theme blog_owl">
        <div *ngFor="let news of newsList" class="newsroom_blog_Item">
          <div (click)="navNewsDetail(news)" class="newsroom_blog_img margin_Bottom16">
            <img [src]="news?.page?.short_image || defaultIMG" [alt]="news?.news_name">
          </div>
          <div>
            <h5 class="font_Size16 font_Weight600 infiniteNightText margin_Bottom12 cursorPointer">{{ news?.news_name }}</h5>
            <p class="font_Size16 font_Weight400 steelWoolText margin_Bottom12">{{news?.page?.short_content}} | <span
                class="phaserBeamText font_Weight600">{{formatDate(news.page.created_at)}}</span> </p>

          </div>

        </div>

      </div>
    </div>
  </section>
</div>
