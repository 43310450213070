import { Component, Inject, PLATFORM_ID, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from '../common_service/http.service';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';
import { isPlatformBrowser, isPlatformServer, } from '@angular/common';
import { environment } from 'src/environments/environment';
import { SessionStorageService } from '../session-service.service';
import { ApiService } from '../blog/shared/data-service.service'


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  slug: any = '';
  menu: any = [];
  search = false;
  private searchTerms = new Subject<string>();
  public currentSearchTerm: string = '';
  websiteUrl = environment.WebsiteApiUrl;
  emailUrl = environment.EmailAPIUrl;
  searchResults: any = [];
  footer = false;
  isBrowser: Boolean = false;
  isServer: Boolean = false;
  constructor(
    private _router: Router,
    public _service: HttpService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private sessionStorageService: SessionStorageService,
    private route: ActivatedRoute,
    private elementRef: ElementRef,
    private apiService: ApiService
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
    this.isServer = isPlatformServer(this.platformId);
    this._service.showFooter.subscribe((x) => {
      if (x != null) {
        this.footer = x;
      }
    });
  }


  ngOnInit() {

    this.searchTerms.pipe(
      debounceTime(1000),
      distinctUntilChanged()
    ).subscribe(term => {
      this.currentSearchTerm = term;
      this.onSearch(term);
    });

    let getMenu = setInterval(() => {

      if (this.sessionStorageService?.menu?.length) {
        this.menu = this.sessionStorageService?.menu?.filter((e: any) => e.menu_name !== 'Home');
        clearInterval(getMenu);
      }

    }, 10)

    this.route.url.subscribe(segments => {
      const urlParts = segments.map(segment => segment.path);
      const slug = urlParts.join('/');

      this.slug = slug || "Home";


    });

    // let getall = setInterval(() => {
    //   let id = this.sessionStorageService.getItem("company_id");
    //   if (id) {



    //     clearInterval(getall);

    //   }
    // }, 10)



  }

  navTo(route: any, cond: boolean = true) {
    if (cond) {
      this._router.navigate([`/${route}`]);
    }
  }

  homeNavigation(key: string) {
    this.toggleClass()
    this._router.navigate([`/${key}`])
    this.slug = 'Home'

  }


  // search

  searchHandle() {
    this.search = !this.search; const inputElement = this.elementRef.nativeElement.querySelector('#search-input');

    if (inputElement) {
      inputElement.value = '';
    }
    this.currentSearchTerm = '';
    this.searchResults = [];
  }

  onSearchInput(event: any): void {
    const searchTerm = event.target.value;
    this.searchTerms.next(searchTerm);
  }

  onEnterKeyPress(e: any): void {







    this._router.navigate(['/search/detail'], {
      queryParams: {
        search: e.target.value,

      },
    });
    this.searchHandle()

  }



  onSearch(searchTerm: any) {

    if (isPlatformBrowser(this.platformId)) {
      let id = this.sessionStorageService.getItem('company_id') ?? environment.companyId
      console.log('id::: ', id);
      // const inputValue = this.input.nativeElement.value.trim();
      if (searchTerm !== '' && this.search) {
        // Add your API call logic here
        let api = `${this.websiteUrl}/api/Website/search?CompanyId=${environment.companyId}&SearchFor=${searchTerm}`;
        this._service.get(api).subscribe((el: any) => {
          let thanksSlugs = ["contact-us/thank-you", "submit-artwork/thank-you", "subscription/thank-you"]
          this.searchResults = el.filter((e: any) => !(thanksSlugs.includes(e?.slug)))

        })
      } else {
        this.searchResults = []
      }
    }


  }

  toggleClass() {
    var button = document.getElementById("toggleButton");
    if (button) {
      if (button.classList.contains("showMenu")) {
        // If it has the class, remove it
        button.classList.remove("showMenu");
        // button.textContent = "Add Class";
      } else {
        // If it doesn't have the class, add it
        button.classList.add("showMenu");
        // button.textContent = "Remove Class";
      }
    }

  }

  goToSearch(item: any) {

    if (item?.category == 'WEBSITE') {
      this.navTo(item.slug)
    } else if (item?.category === 'BLOG') {
      this.navTo(`card-talk/${item?.slug}`)
    } else if (item?.category === 'NEWS') {
      this.navTo(`news/detail/${item?.id}`)
    }
    const inputElement = document.getElementById('search-input') as HTMLInputElement;
    if (inputElement) {
      inputElement.value = '';
    }
    this.searchHandle()
    this.currentSearchTerm = ''

    this.searchResults = []

  }



}
