<!-- <app-header/> -->

<div *ngIf="isBrowser && footer">
  <!-- <div *ngIf="slug==='Home'|| slug==='' " class="notification_panel">
    <div class="container">
      <div>
        <div></div>
        <div>
          <p class="whiteColor_Text font_Size14 font_Weight500 textAlign_center"> {{latestNews.news_name}} 🎉 <a
              (click)="navNews(latestNews)"
              class="cursor_Pointer whiteColor_Text textDecoration_Underline hrefLinkHover"> <span
                class="margin_Right12">Explore
                now</span><img class="whiteFilter"
                src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets/threeArrowLeft_icon.svg"
                alt="threeArrowLeft_icon" /> </a> </p>
        </div>
        <div></div>
      </div>
    </div>
  </div> -->
  <div *ngFor="let temp of dynamicContent">
    <!-- <table> -->
    <!-- <tr> -->
    <!-- <td> -->
    <div [innerHTML]="temp.template_html | sanitizer"></div>
    <!-- </td> -->
    <!-- </tr> -->
    <!-- </table> -->

    <div style="clear: both"></div>
  </div>
</div>

<div *ngIf="isServer" style="display: none;">
  <div *ngIf="slug==='Home'|| slug==='' " class="notification_panel">
    <div class="container">
      <div>
        <div></div>
        <div>
          <p class="whiteColor_Text font_Size14 font_Weight500 textAlign_center"> {{latestNews.news_name}} 🎉 <a
              (click)="navNews(latestNews)"
              class="cursor_Pointer whiteColor_Text textDecoration_Underline hrefLinkHover"> <span
                class="margin_Right12">Explore
                now</span><img class="whiteFilter"
                src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets/threeArrowLeft_icon.svg"
                alt="threeArrowLeft_icon" /> </a> </p>
        </div>
        <div></div>
      </div>
    </div>
  </div>
  <div *ngFor="let temp of dynamicContent">
    <!-- <table> -->
    <!-- <tr> -->
    <!-- <td> -->
    <div [innerHTML]="temp.template_html | sanitizer"></div>
    <!-- </td> -->
    <!-- </tr> -->
    <!-- </table> -->

    <div style="clear: both"></div>
  </div>
</div>


<div *ngIf="notFoundPage">
  <div class="errorBlock">

    <div class="errorSection">

      <div class="container-fluid">

        <div class="row">
          <!-- <div class="col-md-12">
      <div>
        <a class="pli_brandLogo" href="index.html">
          <img src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/pli_logo.svg" alt="pli_logo">
        </a>
      </div>
    </div> -->

        </div>


        <div class="row">
          <div class="col-md-5 oppstextcenter">

            <div>

              <div class="oppsheadingtext"> Oops! Page Not Found</div>

              <div class="oppstext">The page you are looking for might be in another castle</div>

              <button class="smallMainbutton infiniteNightBtn_clr" (click)="homeNavigation('')">Back to Home</button>

            </div>



          </div>
          <div class="col-md-7">

            <img src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets-new/404.png"
              style="width: 100%; padding-bottom: 50px;">



          </div>
        </div>




      </div>


    </div>


  </div>

</div>



<!-- <div *ngIf="slug.includes('blog/list')">
  <app-blog-list> </app-blog-list>
</div>

<div *ngIf="slug.includes('card-talk')">
  <app-blog-detail> </app-blog-detail>>
</div>

<div *ngIf="slug.includes('news/list')">
  <app-news-list> </app-news-list>
</div>

<div *ngIf="slug.includes('news/detail')">
  <app-news-detail> </app-news-detail>>
</div> -->

<div class="modal" id="myModal">
  <div class="modal-dialog modal-lg modal-dialog-centered">
    <div class="modal-content">

      <!-- Modal Header -->
      <div class="modal-header">
        <h4 class="modal-title">{{ jobTitle }}</h4>
        <button type="button" class="close" data-dismiss="modal">&times;</button>
      </div>

      <!-- Modal Body -->
      <div class="modal-body popupHeight" [innerHTML]="jobDescription"></div>

      <!-- Modal Footer -->
      <div class="modal-footer">
        <button type="button" class="smallMainbutton infiniteNightBtn_clr" (click)="apply()">Apply</button>
        <button type="button" class="smallMainbutton infiniteNightBtn_clr" data-dismiss="modal">Close</button>
      </div>

    </div>
  </div>
</div>
