<div *ngIf="footer">
  <section>
    <div class="container">

      <div (click)="navigate()">
        <a class="Display_Flex align_item_Center backarrow textDecoration_unset" href="Blogs.html">
          <div><img class="backarrowicon"
              src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/demo-sayshop/slices/blogs/backarrow.svg" alt="">
          </div>
          <div class="back_text">
            Back
          </div>
        </a>
      </div>


      <div class="row">

        <div class="col-md-8">
          <div>
            <div [innerHTML]=" blogContent | sanitizer"></div>
          </div>
        </div>
        <div class="col-md-4 rightblogcontentfull">
          <div *ngIf="recentBlogList?.length">
            <div class="blog_subhead textblack">
              Latest
            </div>


            <div *ngFor="let blog of recentBlogList">
              <div class="Display_Flex rightblogcontent">
                <div *ngIf="blog?.page?.short_image">
                  <img [src]="blog?.page?.short_image" class="rightblogcontentimg"  alt="" />
                </div>
                <div class="rightsmallblogs">
                  <a (click)="navDetail(blog.slug)" href="javascript:void(0)"
                    class="textDecoration_unset">{{blog?.page?.short_content}}</a>
                  <p>{{formatDate(blog?.page?.created_at)}}</p>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </section>
  <section *ngIf="newsList?.length" class="newsroom_blog">
    <div class="container">

      <div class="DeskFlex_Mobileblock justify-content_Spacebetween align_item_Center margin_Bottom34">
        <div>
          <h2 class="font_Size32 infiniteNightText font_Weight700">Newsroom</h2>
          <div>
            <img src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets/small_phaserBeamLinebottom.svg"
              alt="small_phaserBeamLinebottom">
          </div>

        </div>
        <div>
          <button (click)="navNewslist()" class="Mainbutton infiniteNightBtn_clr">Show all</button>
        </div>
      </div>


      <div class="owl-carousel owl-theme blog_owl">
        <div *ngFor="let news of newsList" class="newsroom_blog_Item">
          <div (click)="navNewsDetail(news)" class="newsroom_blog_img margin_Bottom16 cursor_Pointer">
            <img [src]="news?.page?.short_image || defaultIMG" [alt]="news?.news_name">
          </div>
          <div>
            <h5 class="font_Size16 font_Weight600 infiniteNightText margin_Bottom12">{{ news?.news_name }}</h5>
            <p class="font_Size16 font_Weight400 steelWoolText margin_Bottom12">{{news?.page?.short_content}} | <span
                class="phaserBeamText font_Weight600">{{formatDate(news.page.created_at)}}</span> </p>

          </div>

        </div>

      </div>
    </div>
  </section>

</div>
