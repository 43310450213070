import { Component, Inject, PLATFORM_ID,TransferState, makeStateKey } from '@angular/core';
import { HttpService } from '../../common_service/http.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../blog/shared/data-service.service';
import { SessionStorageService } from '../../session-service.service';
import { isPlatformBrowser } from '@angular/common';
import { Title } from '@angular/platform-browser';
import { environment } from '../../../environments/environment'
declare var $: any;
const MY_KEY = makeStateKey<string>('myKey');

@Component({  
  selector: 'app-news-detail',
  templateUrl: './news-detail.component.html',
  styleUrls: ['./news-detail.component.scss']
})
export class NewsDetailComponent {
  CompanyID : any = ''
  newsId: number = 0;
  newsDetail: any = {};
  recentNewsList: any = [];
  defaultIMG: string = environment.defaultImg;
  fb_url: string = '';
  twitter_url: string = '';
  mail_url: string = '';
  linked_url: string = '';
  newsContent: string = '';
  blogList: any = [];
  footer = false;
  constructor(
    private route: ActivatedRoute,
    public service: HttpService,
    private _router: Router,
    private apiService: ApiService,
    private sessionStorageService: SessionStorageService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private titleService: Title,
    public _service: HttpService,
    private transferState :TransferState

  ) {
    this._service.showFooter.subscribe((x) => {
      if (x != null) {
        this.footer = x;
      }
    });
  }


  ngOnInit() {
    // this.apiService.getMenu();
    this.route.params.subscribe(params => {
      this.newsId = params['id'];
       this.CompanyID = this.sessionStorageService.getItem("company_id");

      let menu = this.sessionStorageService.menu;
      if(this.CompanyID && menu?.length){
        this.getAllData()
      }else{
        this.apiService.getMenu()?.subscribe(
          (response)=>{
            this.transferState.set(MY_KEY, response?.company_id);
            this.getAllData()
          },(error) => {
            // Handle error if needed
            console.error('Error fetching menu', error);
          }
         );
        
      }

    });

  }

  getAllData(){
    this.CompanyID = this.transferState.get(MY_KEY, '') === '' ? this.sessionStorageService.getItem("company_id") : this.transferState.get(MY_KEY, '')
    // console.log('this.CompanyID::: ', this.CompanyID);
    // this.CompanyID = environment.companyId;
    let menu = this.sessionStorageService.menu

    if(this.CompanyID && menu?.length){
      this._service.hideAndShoeFooter(true);
if( this.recentNewsList.length === 0){
  this.apiService.getAllNews(this.CompanyID, true).subscribe({
    next: (data: any) => {
      this.recentNewsList = data?.news || []
    },
    error: (error) => {
      console.log("error", error)
    }
  });
}

    
      this.apiService.getNewsDetail(this.newsId, this.CompanyID).subscribe({
        next: (data: any) => {
          this.newsDetail = data;
          this.newsContent = JSON?.parse(data?.page?.page_data)?.[0]?.template_html || "";
          this.titleService.setTitle(`${this.newsDetail?.news_name} - PLI`)
          this.fb_url =`https://facebook.com/sharer/sharer.php?u=${environment.Site_url}card-talk/${this.newsDetail?.slug}`
          this.twitter_url =`https://twitter.com/intent/tweet/?text=${this.newsDetail?.news_name};url=${environment.Site_url}card-talk/${this.newsDetail?.slug}`
          this.mail_url =`mailto:?subject=${this.newsDetail?.news_name};body=${environment.Site_url}card-talk/${this.newsDetail?.slug}`
          this.linked_url =`https://www.linkedin.com/shareArticle?mini=true&amp;url=${environment.Site_url}card-talk/${this.newsDetail?.slug};title=${this.newsDetail?.news_name};source=${environment.Site_url}card-talk/${this.newsDetail?.slug}`
          this.service.updateOgTitle(`${this.newsDetail?.news_name} - PLI`);
          this.service.updateTwitterTitle(`${this.newsDetail?.news_name} - PLI`);
          this._service.updateDescription(this.newsDetail?.page?.short_content ?? "");
          this._service.updateOgUrl();
          this._service.updateOgDescription(this.newsDetail?.page?.short_content ?? "");
          this._service.updateOgImage(this.newsDetail?.page?.short_image, true);
          this._service.updateTwitterCard("summary_large_image");
          this._service.updateTwitterUrl();
          this._service.updateTwitterDescription(this.newsDetail?.page?.short_content ?? "");
          this._service.updateTwitterImage(this.newsDetail?.page?.short_image, true);
        },
        error: (error) => {
          console.log("error", error)
        }
      });

if(this.blogList?.length === 0){
  this.apiService.getAllBlogs(this.CompanyID, true).subscribe({
    next: (data: any) => {
      this.blogList = data?.blogs || [];
      setTimeout(() => this.slider(), 100)

    },
    error: (error) => {
      console.log("error", error)
    }
  });
}
      

    

    }
  }


  navigate() {
    this._router.navigate([`news/list`])
  }


  formatDate(dateString: Date) {
    if(!dateString){
      return null
      }
    const months = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];

    const date = new Date(dateString);
    const day = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();

    return `${day} ${month} ${year}`;
  };


  slider() {
    if (isPlatformBrowser(this.platformId)) {
      $('.blog_owl').owlCarousel({
        navText: ['<img src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets/left_arrow.svg">', '<img src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/pli-assets/righarrow_icon.svg">'],
        loop: true,
        margin: 10,
        nav: false,
        dots: false,
        center: false,
        autoplay: true,
        autoplayHoverPause: true,
        autoplaySpeed: 5000,
        responsive: {
          0: {
            items: 1
          },
          600: {
            items: 1
          },
          1000: {
            items: 4
          }
        }
      })

      // counter number
      $('.counter_number').each(() => {
        $(this).prop('Counter', 0).animate({
          Counter: $(this).text()
        }, {
          duration: 5000,
          easing: 'swing',
          step: (now: any) => {
            $(this).text(Math.ceil(now));
          }
        });
      });


    }
  }

  navigateBlogDetail(id: any) {
    this.apiService.setBlogId(id);

  }

  navBloglist() {
    this?._router?.navigate(['blog/list'])

  }

  navDetail(obj:any){
    if(obj.category === 'BLOG'){
      this._router.navigate([`card-talk/${obj.slug}`]);
    }else{
      this._router.navigate([`news/detail/${obj?.news_id}`])
    }
  
  }


}
