<div *ngIf="footer && menu.length && isBrowser" id="#header">
  <div class="navbar-area  navbar-expand-lg siteHeader_wrapper">
    <div class="container">
      <nav class="navbar">
        <div class="Display_Flex align_item_Center justify-content_Spacebetween mobileHeader">

          <div>
            <a class="navbar-brand" href="/">
              <img src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/demo-sayshop/slices/theecode.svg"
                alt="logo" />
            </a>
          </div>
          <div class="MobileToggle">
            <button onclick="toggleClass()">
              <span></span>
              <span></span>
              <span></span>
            </button>
          </div>
        </div>



        <div id="toggleButton" class="mobileMenu" aria-expanded="fa">

          <div id="navbarSupportedContent">
            <ul class="navbar-nav m-auto">
              <li class="nav-item">
                <a class="active nav-link" (click)="navTo('')"><img
                    src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/demo-sayshop/slices/home_icon.svg"
                    alt="home_icon" /></a>
              </li>

              <li *ngFor="let tab of menu" (click)="navTo(tab.slug,!tab.children.length)" class="nav-item dropdown">
                <a [ngClass]="{'dropdown-toggle': tab.children.length}" id="navbarDropdownMenuLink_one"
                  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  {{tab.menu_name}}
                </a>
                <ul *ngIf="tab.children.length" class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink_one">
                  <li *ngFor="let subMenu of tab.children"><a class="dropdown-item" (click)="navTo(subMenu.slug)">

                      <img
                        src="https://theecode-sayshop.s3.us-west-1.amazonaws.com/demo-sayshop/slices/maki_arrow.svg" />
                      <Span>{{subMenu.menu_name}}</Span>
                    </a></li>

                </ul>
              </li>

              <li (click)="navTo('blog/list')" class="nav-item">
                <a id="navbarDropdownMenuLink_one" aria-haspopup="true" aria-expanded="false">
                  Blogs
                </a>

              </li>

            </ul>

          </div>
        </div>
        <!-- <div class="optionMenu">
                    <div class="Display_Flex align_item_Center gap_20">
                      <div>
                        <a href="https://platform.theecode.io/register" target="_blank"> <button
                            class="Mainbutton primary_clr custButtonheight">Sign&nbsp;up&nbsp;for&nbsp;free
                          </button></a>
                      </div>
                      <div>
                        <a href="contactus.html" class="emptybtn fontSize14 fontWeight400 neroText textDecoration_underline_unset">Let's
                          Connect</a>
                      </div>
                    </div>
                  </div> -->
      </nav>
    </div>
  </div>
</div>